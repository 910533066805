<template>
  <p v-if="advisor.discount">
    <span class="text-neutral-50 text-b-s !mr-2"><span class="hidden xxs:inline-block xxs:visible">from &nbsp;</span>
      <span class="line-through">${{ formatPrice(cheapestFee) }}
        <span class="hidden xxs:inline-block xxs:visible">/min</span>
      </span>
    </span>
    <span class="text-b-s font-bold">${{ formatPrice(discountedCheapestFee) }}/min</span>
  </p>
  <p v-else>
    <span class="text-b-s font-bold">${{ formatPrice(cheapestFee) }}/min</span>
  </p>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AdvisorService } from '../../services/advisor.service';

const props = defineProps<{
  advisor: any
}>();

const cheapestFee = computed(() => AdvisorService.getCheapestFee(props.advisor));
const discountedCheapestFee = computed(() => AdvisorService.getDiscountedPrice(cheapestFee.value, props.advisor));

const formatPrice = (price: number): string => price.toFixed(2);
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorGeneralPrice',
};
</script>
